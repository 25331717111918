import type { DropdownOption } from 'react-day-picker';

export type OptionType = 'year' | 'month';

export type NormalizedOption = {
  id: number;
  title: string;
  value: number;
};

export const getNormalizedOptions = (
  options?: DropdownOption[],
): NormalizedOption[] =>
  (options || []).map((option) => ({
    id: option.value,
    title: option.label,
    value: option.value,
  }));
