import { forwardRef } from 'react';

import { DatePickerRange } from './features/DatePickerRange';
import { DatePickerSingle } from './features/DatePickerSingle';

import type { DatePickerInputProps } from './lib';
import type { DateRange } from '@shared/helpers';
import type { ForwardRefRenderFunction } from 'react';

export type DatePickerProps = {
  anchorClassName?: string;
} & (
  | {
      mode?: 'single';
      value?: Date;
      onChange?: (date?: Date) => void;
    }
  | {
      mode: 'range';
      value?: DateRange;
      onChange?: (range?: DateRange) => void;
    }
) &
  DatePickerInputProps;

const DatePicker: ForwardRefRenderFunction<
  HTMLInputElement,
  DatePickerProps
> = (props, ref) =>
  props.mode === 'range' ? (
    <DatePickerRange ref={ref} {...props} />
  ) : (
    <DatePickerSingle ref={ref} {...props} />
  );
export default forwardRef(DatePicker);
