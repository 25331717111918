import { useLocation, useMatches } from 'react-router-dom';

import type { Breadcrumb } from '@shared/helpers';
import type { RouteObject } from 'react-router-dom';

export const useBreadcrumb = (): Breadcrumb => {
  const location = useLocation();
  const matches: RouteObject[] = useMatches();

  const defaultCrumb =
    matches.findLast((match) => match.handle?.crumb)?.handle?.crumb ?? {};

  return { ...defaultCrumb, ...(location.state?.crumb ?? {}) };
};
