import { memo } from 'react';

import { getMonth, setMonth } from 'date-fns';
import { useDayPicker } from 'react-day-picker';

import { getNormalizedOptions } from '../../lib';
import { CalendarDropdown } from '../CalendarDropdown';

import type { NormalizedOption } from '../../lib';
import type { DropdownProps } from 'react-day-picker';

const MonthsDropdown = (props: DropdownProps) => {
  const { months, goToMonth } = useDayPicker();
  const [currentMonth] = months;
  const currentMonthNum = getMonth(currentMonth.date);

  const options = getNormalizedOptions(props.options);

  const value = options?.find((it) => it.id === currentMonthNum);

  const handleMonthChange = (item: NormalizedOption | null) => {
    if (item && 'value' in item) {
      goToMonth(setMonth(currentMonth.date, item.value));
    }
  };

  return (
    <CalendarDropdown
      items={options}
      value={value}
      onChange={handleMonthChange}
    />
  );
};

export default memo(MonthsDropdown) as typeof MonthsDropdown;
