import { forwardRef, useEffect, useState } from 'react';

import {
  DATE_MASK,
  getDateValueFromString,
  getValueStringFromDate,
} from '../../lib';
import { DatePickerBase } from '../DatePickerBase';

import type { DatePickerInputProps } from '../../lib';
import type { ForwardRefRenderFunction } from 'react';

export type DatePickerSingleProps = {
  value?: Date | null;
  anchorClassName?: string;
  onChange?: (date?: Date) => void;
  onBlur?: VoidFunction;
} & DatePickerInputProps;

const DatePickerSingle: ForwardRefRenderFunction<
  HTMLInputElement,
  DatePickerSingleProps
> = ({ value, onChange, onBlur, ...props }, ref) => {
  const [opened, setOpened] = useState(false);
  const [valueStr, setValueStr] = useState(() => getValueStringFromDate(value));

  const handleOpenChange = (opened: boolean) => {
    if (!opened) {
      onBlur?.();
    }
    setOpened(opened);
  };

  const handleSelect = (date?: Date) => {
    onChange?.(date);
    handleOpenChange(false);
  };

  const handleBlur = () => {
    const date = getDateValueFromString(valueStr);

    onChange?.(date);

    if (!date) {
      setValueStr('');
    }

    onBlur?.();
  };

  useEffect(() => {
    setValueStr(getValueStringFromDate(value));
  }, [value]);

  return (
    <DatePickerBase
      ref={ref}
      onOpenChange={handleOpenChange}
      onChangeText={setValueStr}
      mask={DATE_MASK}
      text={valueStr}
      opened={opened}
      mode="single"
      selected={value ?? undefined}
      onSelect={handleSelect}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default forwardRef(DatePickerSingle);
