import { memo, useMemo, useState } from 'react';

import clsx from 'clsx';
import { ru } from 'date-fns/locale/ru';
import { DayPicker } from 'react-day-picker';

import { calendarTheme } from './calendar.theme';
import { MonthsDropdown } from './ui/MonthsDropdown';
import { YearsDropdown } from './ui/YearsDropdown';
import { Popover } from '../Popover';

import type { PopoverProps } from '../Popover';
import type { FC } from 'react';
import type { DayPickerProps } from 'react-day-picker';

export type CalendarProps = DayPickerProps & PopoverProps;

const components: DayPickerProps['components'] = {
  MonthsDropdown,
  YearsDropdown,
};

const Calendar: FC<CalendarProps> = ({
  children,
  isOpened,
  onOpenChange,
  isAutoWidth,
  anchorClassName,
  popoverClassName,
  className,
  classNames,
  ...props
}) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());

  const theme = useMemo(
    () => (classNames ? { ...calendarTheme, ...classNames } : calendarTheme),
    [classNames],
  );

  return (
    <Popover
      isOpened={isOpened}
      onOpenChange={onOpenChange}
      anchorClassName={anchorClassName}
      popoverClassName={popoverClassName}
      isAutoWidth={false}
      anchor={children}
    >
      <DayPicker
        hideNavigation
        captionLayout="dropdown"
        month={currentMonth}
        locale={ru}
        onMonthChange={setCurrentMonth}
        showOutsideDays
        className={clsx('p-3', className)}
        classNames={theme}
        components={components}
        {...props}
      />
    </Popover>
  );
};

export default memo(Calendar);
