import * as assets from '@shared/assets';

import type { FC, SVGAttributes } from 'react';

export enum IconKind {
  ARROW_DOWN = 'ARROW_DOWN',
  BARS = 'BARS',
  CALENDAR = 'CALENDAR',
  DOWNLOAD = 'DOWNLOAD',
  FOLDER_PLUS = 'FOLDER_PLUS',
  HOME = 'HOME',
  NOTIFICATION = 'NOTIFICATION',
  PENCIL = 'PENCIL',
  SEARCH = 'SEARCH',
  SETTING = 'SETTING',
  TRASH = 'TRASH',
  USER_ADD = 'USER_ADD',
  USERS = 'USERS',
  SORT = 'SORT',
  HAMBURGER = 'HAMBURGER',
  TICK = 'TICK',
  USERS_GROUP = 'USERS_GROUP',
  DAIRY = 'DAIRY',
  KEY = 'KEY',
  ADDRESS_BOOK = 'ADDRESS_BOOK',
  CONFIGURE = 'CONFIGURE',
  CROSS = 'CROSS',
  UNLOCK = 'UNLOCK',
  MARK = 'MARK',
  LOCK = 'LOCK',
  CLOCK = 'CLOCK',
  DELETE_ILLUSTRATION = 'DELETE_ILLUSTRATION',
  USER_ADD_ILLUSTRATION = 'USER_ADD_ILLUSTRATION',
  SUCCESS_ILLUSTRATION = 'SUCCESS_ILLUSTRATION',
  LOCK_ILLUSTRATION = 'LOCK_ILLUSTRATION',
  UNLOCK_ILLUSTRATION = 'UNLOCK_ILLUSTRATION',
  EDIT_ILLUSTRATION = 'EDIT_ILLUSTRATION',
  NOT_FOUND_ILLUSTRATION = 'NOT_FOUND_ILLUSTRATION',
  KEY_ILLUSTRATION = 'KEY_ILLUSTRATION',
  SUBJECT_ILLUSTRATION = 'SUBJECT_ILLUSTRATION',
  USERS_ILLUSTRATION = 'USERS_ILLUSTRATION',
  ERROR_ILLUSTRATION = 'ERROR_ILLUSTRATION',
  ARROW_CHECKED = 'ARROW_CHECKED',
  JOURNAL = 'JOURNAL',
}

export const ICON_KIND_SET = new Set(Object.values(IconKind));

export const ICON_MAP: Record<
  IconKind,
  FC<SVGAttributes<SVGElement> & { title?: string }>
> = {
  [IconKind.ARROW_DOWN]: assets.ArrowDownIcon,
  [IconKind.BARS]: assets.BarsIcon,
  [IconKind.CALENDAR]: assets.CalendarIcon,
  [IconKind.DOWNLOAD]: assets.DownloadIcon,
  [IconKind.FOLDER_PLUS]: assets.FolderPlusIcon,
  [IconKind.HOME]: assets.HomeIcon,
  [IconKind.NOTIFICATION]: assets.NotificationIcon,
  [IconKind.PENCIL]: assets.PencilIcon,
  [IconKind.SEARCH]: assets.SearchIcon,
  [IconKind.SETTING]: assets.SettingIcon,
  [IconKind.TRASH]: assets.TrashIcon,
  [IconKind.USER_ADD]: assets.UserAddIcon,
  [IconKind.USERS]: assets.UsersIcon,
  [IconKind.DELETE_ILLUSTRATION]: assets.DeleteIllustration,
  [IconKind.USER_ADD_ILLUSTRATION]: assets.UserAddIllustration,
  [IconKind.SUCCESS_ILLUSTRATION]: assets.SuccesIllustration,
  [IconKind.LOCK_ILLUSTRATION]: assets.LockIllustration,
  [IconKind.UNLOCK_ILLUSTRATION]: assets.UnlockIllustration,
  [IconKind.EDIT_ILLUSTRATION]: assets.EditIllustration,
  [IconKind.NOT_FOUND_ILLUSTRATION]: assets.NotFoundIllustration,
  [IconKind.KEY_ILLUSTRATION]: assets.KeyIllustration,
  [IconKind.USERS_ILLUSTRATION]: assets.UsersIllustration,
  [IconKind.SORT]: assets.SortDownIcon,
  [IconKind.HAMBURGER]: assets.HamburgerIcon,
  [IconKind.TICK]: assets.TickIcon,
  [IconKind.USERS_GROUP]: assets.UsersGroupIcon,
  [IconKind.DAIRY]: assets.DairyIcon,
  [IconKind.KEY]: assets.KeyIcon,
  [IconKind.ADDRESS_BOOK]: assets.AddressBookIcon,
  [IconKind.CONFIGURE]: assets.ConfigureIcon,
  [IconKind.CROSS]: assets.CrossIcon,
  [IconKind.UNLOCK]: assets.UnlockIcon,
  [IconKind.MARK]: assets.MarkIcon,
  [IconKind.LOCK]: assets.LockIcon,
  [IconKind.SUBJECT_ILLUSTRATION]: assets.SubjectIllustration,
  [IconKind.ERROR_ILLUSTRATION]: assets.ErrorIllustration,
  [IconKind.ARROW_CHECKED]: assets.ArrowChecked,
  [IconKind.JOURNAL]: assets.JournalIcon,
  [IconKind.CLOCK]: assets.ClockIcon,
};

export const isIconKind = (value: unknown): value is IconKind =>
  ICON_KIND_SET.has(value as IconKind);
