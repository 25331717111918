import { useLayoutEffect, useState } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';
import clsx from 'clsx';

import { debounce } from '@shared/helpers';

import styles from './popover.module.css';

import type { FC, ReactNode } from 'react';

export interface PopoverProps {
  isOpened?: boolean;
  onOpenChange?: (opened: boolean) => void;
  children?: ReactNode;
  anchor?: ReactNode;
  isAutoWidth?: boolean;
  anchorClassName?: string;
  popoverClassName?: string;
}

export const PopoverTrigger = PopoverPrimitive.Trigger;

export const Popover: FC<PopoverProps> = ({
  children,
  anchor,
  isAutoWidth = true,
  anchorClassName,
  popoverClassName,
  isOpened,
  onOpenChange,
}) => {
  const [popoverRef, setPopoverRef] = useState<HTMLElement | null>(null);
  const [parentRef, setParentRef] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    if (!(isOpened && parentRef && popoverRef)) {
      return;
    }

    const setWidth = () => {
      if (!isAutoWidth) {
        popoverRef.style.width = '';

        return;
      }

      const parentWidth = parentRef.getBoundingClientRect?.()?.width || 0;

      popoverRef.style.width = parentWidth ? `${parentWidth}px` : '';
    };

    const debounceSetWidth = debounce(setWidth, 150);

    window.addEventListener('resize', debounceSetWidth);
    setWidth();

    return () => {
      window.removeEventListener('resize', debounceSetWidth);
    };
  }, [isAutoWidth, popoverRef, parentRef, isOpened]);

  return (
    <PopoverPrimitive.Root open={isOpened} onOpenChange={onOpenChange}>
      <PopoverPrimitive.Anchor
        ref={setParentRef}
        className={clsx('inline', anchorClassName)}
      >
        {anchor}
      </PopoverPrimitive.Anchor>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          ref={setPopoverRef}
          align="center"
          sideOffset={4}
          className={clsx(
            styles.popover,
            'data-[state=open]:animate-in',
            'data-[state=closed]:animate-out',
            'data-[state=closed]:fade-out-0',
            'data-[state=open]:fade-in-0',
            'data-[state=closed]:zoom-out-95',
            'data-[state=open]:zoom-in-95',
            'data-[side=bottom]:slide-in-from-top-2',
            'data-[side=left]:slide-in-from-right-2',
            'data-[side=right]:slide-in-from-left-2',
            'data-[side=top]:slide-in-from-bottom-2',
            popoverClassName,
          )}
        >
          {children}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};
