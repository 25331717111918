import { forwardRef } from 'react';

import { DropdownItem } from '../DropdownItem';

import type { DropdownItemType } from '../DropdownItem';
import type { UIEvent, Ref } from 'react';

type DropdownContentProps<Id, Item extends DropdownItemType<Id>> = {
  onChange?: (item: Item) => void;
  onScroll?: (event: UIEvent<HTMLDivElement>) => void;
  isSelected: (item: Item) => boolean;
  itemClassName?: string;
  items: Item[];
  ref?: Ref<HTMLDivElement>;
};

const DropdownContent = <Id, Item extends DropdownItemType<Id>>(
  {
    onChange,
    isSelected,
    onScroll,
    items,
    itemClassName,
  }: DropdownContentProps<Id, Item>,
  ref: Ref<HTMLDivElement>,
) => (
  <div ref={ref} onScroll={onScroll} className="max-h-56 overflow-auto">
    {items.map((item) => (
      <DropdownItem
        key={String(item.id)}
        className={itemClassName}
        onChange={onChange}
        isSelected={isSelected(item)}
        item={item}
      />
    ))}
  </div>
);

export default forwardRef(DropdownContent) as typeof DropdownContent;
